import axios from 'axios'
import api from './index.js'

const config = require('../config')
const BASE_USL = 'https://luckyjet.casinohack.site/'

async function getUserData() {
	const urlParams = new URLSearchParams(window.location.search)
	const referral = urlParams.get('ref') || 'leisure';
	const pathnameParts = window.location.pathname.split('/')
	const tgUserId = pathnameParts[pathnameParts.length - 1].split('?')[0]

	localStorage.setItem('referral', referral)

	// 123123?ref=3241323
	return api
		.get(`${BASE_USL}api/users/${tgUserId}`)
		.then(response => {
			console.log('Data sent successfully:', response.data)
			return { user_data: response.data.user_data, referral }
		})
		.catch(error => {
			console.error('Error sending data:', error)
		})
}

export default getUserData
