import axios from 'axios';

const config = require('../config');

const BASE_USL = 'https://luckyjet.casinohack.site/' 

async function getUserGeoData() {
  const pathnameParts = window.location.pathname.split('/');
  const tgUserId = pathnameParts[pathnameParts.length - 1];

  return axios
    .get(`${BASE_USL}users/${tgUserId}`)
    .then((response) => {
      console.log('GEO sent successfully:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error sending data:', error);
    });
}

export default getUserGeoData;
