import React, { useEffect, useState } from 'react'
import { Triangle } from 'react-loader-spinner'

import getUserData from '../../API/getUserData'
import getUserGeoData from '../../API/getUserGeoData'
import Auth from '../Auth'
import './index.scss'

function App() {
	const [languageCode, setLanguageCode] = useState(null)
	const [date, setDate] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [userGeo, setUserGeo] = useState({}) // Стейт для объекта с гео
	const [referral, setRef] = useState('')

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userData = await getUserData()
				const userGeoData = await getUserGeoData()
				if (userGeoData && userGeoData.user && userGeoData.user.country) {
					setUserGeo({
						userCountry: userGeoData.user.country,
						userISO: userGeoData.user.languages,
					})
				} else {
					// window.location.reload()
				}
				setLanguageCode(userData.user_data.language_code)
				setDate(userData.user_data.date)

				setRef(userData.referral)

				setIsLoading(false)
			} catch (error) {
				console.error('Error fetching data:', error)
				setIsLoading(false)
			}
		}

		fetchData()
	}, [getUserData, getUserGeoData])

	return (
		<div className='wrapper'>
			{isLoading ? (
				<Triangle
					visible='true'
					height='120'
					width='120'
					color='#b172ff'
					ariaLabel='triangle-loading'
					wrapperClass='triangle-loading'
				/>
			) : (
				languageCode && (
					<Auth
						languageCode={languageCode}
						userGeo={userGeo}
						referral={referral}
					/>
				) // передаем пропсом объект с гео
			)}
		</div>
	)
}

export default App
