/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Bounce, ToastContainer, toast } from 'react-toastify';

import getUserData from '../../API/getUserData';
import languageDataAlerts from '../../helpers/languageDataAlerts';
import {languageDataDeposit, enDefaultRevsh} from '../../helpers/languageDataDeopist';
import Preloader from '../Design/Preloader';
import './index.scss';
import LuckyJet from '../LuckyJet';

function Deposit({ languageCode, userGeo }) {
  const [userNewData, setUserNewData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showNewComponent, setShowNewComponent] = useState(false);
  const {userISO, userCountry} = userGeo; 
  const languageData = languageDataDeposit[userISO] || languageDataDeposit[languageCode] || enDefaultRevsh;
  const languageDataAlert = languageDataAlerts[languageCode] || languageDataAlerts.en;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getUserData();
        setUserNewData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleButtonClick = async () => {
    try {
      const data = await getUserData();
      const amount = +data.user_data.amount;
      const isFrLanguage = languageCode === 'fr';
      const isAzLanguage = userISO === 'AZ';
      const isINLanguage = userISO === 'IN';

      if ((isFrLanguage && amount >= 8) || (isAzLanguage && amount >= 17) || (isINLanguage && amount >= 15) || (!isFrLanguage && !isAzLanguage && !isINLanguage && amount >= 17)) {
        setShowNewComponent(true);
      } else {
        toast.error(languageDataAlert.errorDeposit);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  return (
    <div>
      {isLoading && showNewComponent ? (
        <Triangle
          visible='true'
          height='120'
          width='120'
          color='#b172ff'
          ariaLabel='triangle-loading'
          wrapperClass='triangle-loading'
        />
      ) : (
        !isLoading && showNewComponent && <LuckyJet />
      )}
      {!isLoading && !showNewComponent && (
        <div className='deposit-container'>
          <ToastContainer theme='dark' />
          <div className='lucky-jet-design'>
            <Preloader />
          </div>
          <div className='deposit-main'>
            <div className='deposit-main__form'>
              <div className='form-top'>
                <h1 className='form-title'>
                  {languageData.title1}
                  <br />
                  {languageData.title2}
                </h1>
                <h2 className='form-subtitle'>
                  {languageData.subtitle1}
                  <br />
                  {languageData.subtitle2}
                  <span>{languageData.subtitle3}</span>
                </h2>
                <button className='button-continue' onClick={handleButtonClick}>
                  {languageData.buttonGetSignal}
                  <div className='button-continue__arrow'>
                    <div className='button-arrow' />
                  </div>
                </button>
              </div>

              <hr className='form-separator' />
              <div className='form-bottom'>
                <h2 className='form-bottom__text'>{languageData.downText}</h2>
                <a href='https://1wimdx.life/casino/list?open=deposit&p=lkna' target='blank'>
                  <button className='button-deposit'>
                    <div className='button-deposit__money' />
                    {languageData.buttonDeposit}
                    <div className='button-deposit__arrow' />
                  </button>
                </a>
                <div className='form-openAi-logo' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Deposit;
