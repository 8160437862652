
const cisRevsh = {
    title1: 'Остался один шаг',
    title2: 'к лучшему боту',
    subtitle1: 'Для получения доступа к сигналам LuckyJet',
    subtitle2: 'сделайте первый депозит на ',
    subtitle3: ' 20$ или больше',
    buttonGetSignal: 'Получить сигнал',
    buttonDeposit: 'Сделать депозит',
    downText: 'Начни зарабатывать уже прямо сейчас',
  }

const frRevsh = {
    title1: 'Un pas a gauche',
    title2: 'vers un meilleur robot',
    subtitle1: 'Pour acceder aux signaux de LuckyJet',
    subtitle2: 'effectuez un premier depot de',
    subtitle3: '10$ ou plus',
    buttonGetSignal: 'Obtenir un signal',
    Time: 'Register',
    buttonDeposit: 'Faire un depot',
    downText: 'Commencez a gagner de largent des maintenant',
  }

const esRevsh = {
  title1: 'Un paso mas',
  title2: 'a un bot mejor',
  subtitle1: 'Para obtener acceso a las senales LuckyJet',
  subtitle2: 'haga su primer deposito de',
  subtitle3: '25$ o mas',
  buttonGetSignal: 'Obtener senal',
  Time: 'Register',
  buttonDeposit: 'Make a deposit',
  downText: 'Empieza a ganar ahora mismo',
}

const azCPA = {
  title1: 'One step left',
  title2: 'to a better bot',
  subtitle1: 'To access the LuckyJet signals',
  subtitle2: 'make your first deposit of',
  subtitle3: '20$ or more',
  buttonGetSignal: 'Get signals',
  Time: 'Register',
  buttonDeposit: 'Make a deposit',
  downText: 'Start earning right now',
}
const inCPA = {
  title1: 'One step left',
  title2: 'to a better bot',
  subtitle1: 'To access the LuckyJet signals',
  subtitle2: 'make your first deposit of',
  subtitle3: '18$ or more',
  buttonGetSignal: 'Get signals',
  Time: 'Register',
  buttonDeposit: 'Make a deposit',
  downText: 'Start earning right now',
}

const enDefaultRevsh = {
  title1: 'One step left',
  title2: 'to a better bot',
  subtitle1: 'To access the LuckyJet signals',
  subtitle2: 'make your first deposit of',
  subtitle3: '20$ or more',
  buttonGetSignal: 'Get signals',
  Time: 'Register',
  buttonDeposit: 'Make a deposit',
  downText: 'Start earning right now',
}

const languageDataDeposit = {
BY: cisRevsh,
RU: cisRevsh,
KZ: cisRevsh,
KG: cisRevsh,
UA: cisRevsh,
TM: cisRevsh,
fr: frRevsh,
ru: cisRevsh,
ES: esRevsh,
AZ: azCPA,
IN: inCPA,
};

export { languageDataDeposit, enDefaultRevsh };

