import React, { useState, useRef, useEffect } from 'react';
import './index.scss';

function PinInput({ length, onComplete }) {
  const [pin, setPin] = useState(Array(length).fill(''));
  const containerRef = useRef(null);

  const handleBackspace = (index) => {
    const newPin = [...pin];
    newPin[index] = '';
    setPin(newPin);

    if (index > 0) {
      containerRef.current.childNodes[index - 1].focus();
    }
  };

  const handleKeyPress = (index, value) => {
    if (!/^\d$/.test(value)) {
      return;
    }

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (index < length - 1 && value !== '') {
      containerRef.current.childNodes[index + 1].focus();
    }

    if (newPin.every((digit) => digit !== '')) {
      onComplete(newPin.join(''));
    }
  };

  useEffect(() => {
    const { childNodes } = containerRef.current;
    childNodes.forEach((node, index) => {
      node.tabIndex = index + 1;
    });
  }, [length]);

  return (
    <div className='container-pin' ref={containerRef}>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          className='pin-input'
          type='text'
          maxLength='1'
          inputMode='numeric'
          value={pin[index]}
          onChange={(e) => handleKeyPress(index, e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              handleBackspace(index);
            }
          }}
        />
      ))}
    </div>
  );
}

export default PinInput;
