import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './components/App';
import './index.scss';

const tg = window.Telegram.WebApp;
tg.expand();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
