/* eslint-disable no-inner-declarations */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import regression from 'regression';

import EmbeddedSite from './test';

import getBackgroundColor from '../../helpers/getBackgroundColor';

import './index.scss';

function LuckyJet() {
  const [historyData, setHistoryData] = useState([]);
  const [status, setStatus] = useState([]);
  const [range1Probability, setRange1Probability] = useState(0);
  const [range2Probability, setRange2Probability] = useState(0);
  const [range3Probability, setRange3Probability] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const frame = document.getElementById('lucky');
    frame.style.opacity = 0;
    setTimeout(() => {
      frame.style.opacity = 1;
    }, 5000);

    const fetchDataFromServer = () => {
      fetch('https://luckyjet.casinohack.site/api/history')
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data.values) && Array.isArray(data.status)) {
            const historyData = data.values[0];
            const { status } = data;
            setStatus(status);
            if (historyData.length > 17) {
              setHistoryData(historyData);
              calculateProbabilities(historyData);
            } else {
              console.error('Ошибка values');
            }
          } else {
            console.error('Ошибка: получен неверный формат данных');
          }
        })
        .catch((error) => {
          console.error('Ошибка при получении данных:', error);
        });
    };

    const calculateProbabilities = (historyData) => {
      if (historyData.length === 0) return;

      const range1Count = historyData.filter(
        (num) => num >= 1.0 && num <= 2.0,
      ).length;
      const range2Count = historyData.filter(
        (num) => num > 2.0 && num <= 10.0,
      ).length;
      const range3Count = historyData.filter(
        (num) => num > 10.0 && num <= 1000.0,
      ).length;

      const totalCount = historyData.length;

      const probabilityRange1 = (range1Count / totalCount) * 100;
      const probabilityRange2 = (range2Count / totalCount) * 100;
      const probabilityRange3 = (range3Count / totalCount) * 100;

      setRange1Probability(probabilityRange1);
      setRange2Probability(probabilityRange2);
      setRange3Probability(probabilityRange3);
    };
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    fetchDataFromServer();
    const intervalId = setInterval(fetchDataFromServer, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`lucky-jet-container ${isLoading ? 'loading' : ''}`}>
      {isLoading && (
        <Triangle
          visible='true'
          height='120'
          width='120'
          color='#b172ff'
          ariaLabel='triangle-loading'
          wrapperClass='triangle-loading'
        />
      )}

      <div className={`lucky-jet-container__anim ${isLoading ? 'hidden' : ''}`}>
        <div className='lucky-jet-container__anim'>
          <div className='lucky-jet'>
            <iframe
              id='lucky'
              className='lucky-jet-window'
              src='https://lucky-jet.gamedev-atech.cc/?exitUrl=https%253A%252F%252F1win.pro%252Fcasino&language=ru&b=demo'
              title='Lucky Jet'
              frameBorder='0'
              scrolling='no'
            />
          </div>
        </div>
        <div className='lucky-jet-container__history'>
          <div className='lucky-jet-container-history-header'>
            <h1 className='lucky-jet-container__history_title'>
              HISTORY ROUNDS
            </h1>
          </div>
          <div className='past-rounds'>
            {historyData.map((value, index) => (
              <div className='lucky-jet-container__history-content'>
                <div
                  key={index}
                  className='lucky-jet-container__history_item'
                  style={{ backgroundColor: getBackgroundColor(value) }}
                >
                  x{value}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='lucky-jet-container__bets'>
          <div className='lucky-jet-container-bets-header'>
            <h1 className='lucky-jet-container__bets_title'>
              NEXT ROUNDS PREDICTIONS
            </h1>
          </div>
          <div className='container-for-buttons'>
            <div className='lucky-jet-container__bets_items'>
              <div className='lucky-jet-predictor-blue'>
                <div className='lucky-jet-predictor-blue__chance'>
                  {range1Probability.toFixed(2)}%
                </div>
                <div className='lucky-jet-predictor-blue__title'>WIN</div>
              </div>
              <div className='jucky-jet-predictor-range'>1.00X - 2.00X</div>
            </div>

            <div className='lucky-jet-container__bets_items'>
              <div className='lucky-jet-predictor-violet'>
                <div className='lucky-jet-predictor-blue__chance'>
                  {range2Probability.toFixed(2)}%
                </div>
                <div className='lucky-jet-predictor-blue__title'>WIN</div>
              </div>
              <div className='jucky-jet-predictor-range'>2.00X - 10.00X</div>
            </div>
            <div className='lucky-jet-container__bets_items'>
              <div className='lucky-jet-predictor-orange'>
                <div className='lucky-jet-predictor-blue__chance'>
                  {range3Probability.toFixed(2)}%
                </div>
                <div className='lucky-jet-predictor-blue__title'>WIN</div>
              </div>
              <div className='jucky-jet-predictor-range'>10.00X - 1000X</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LuckyJet;
