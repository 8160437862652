const cisRevsh = {
    title1: 'Создай аккаунт',
    title2: 'или войди в свой',
    subtitle1: 'Введите id вашего аккаунта 1win',
    subtitle2: 'зарегистрированого с промокодом ',
    buttonCopy: 'Скопировать промокод',
    buttonRegister: 'Зарегистрироваться',
    checkboxLabel: 'Я Подтверждаю, что я ознакомлен и полностью согласен с',
    checkboxUrl: 'Условиями Соглашения об использовании бота',
}
const frRevsh = {
  title1: 'Creez un compte',
  title2: 'ou connectez-vous',
  subtitle1: 'Entrez lidentifiant de votre compte 1win',
  subtitle2: 'enregistre avec le code promo ',
  buttonCopy: 'Copier le code promo',
  buttonRegister: 'Sinscrire',
  checkboxLabel: 'Je confirme avoir lu et entierement daccord avec',
  checkboxUrl: 'Conditions de laccord dutilisation du robot',
}
const esRevsh = {
  title1: 'Crea una cuenta',
  title2: 'o inicia sesion',
  subtitle1: 'Ingrese su ID de cuenta 1win',
  subtitle2: 'registrado con el código de promoción ',
  buttonCopy: 'Copiar codigo promocional',
  buttonRegister: 'Registro',
  checkboxLabel: 'Confirmo que he leído y estoy totalmente de acuerdo con',
  checkboxUrl: 'Terminos del Acuerdo de uso de bot',
}
const azCPA = {
  title1: 'Create an account',
  title2: 'or log in',
  subtitle1: 'Enter the id of your 1win account ',
  subtitle2: 'registered with the promo code ',
  buttonCopy: 'Copy promo code',
  buttonRegister: 'Register',
  checkboxLabel: 'I confirm that I have read and fully agree with',
  checkboxUrl: 'Terms of the Bot Use Agreement',
}
const inCPA = {
  title1: 'Create an account',
  title2: 'or log in',
  subtitle1: 'Enter the id of your 1win account ',
  subtitle2: 'registered with the promo code ',
  buttonCopy: 'Copy promo code',
  buttonRegister: 'Register',
  checkboxLabel: 'I confirm that I have read and fully agree with',
  checkboxUrl: 'Terms of the Bot Use Agreement',
}
const enDefaultRevsh = {
  title1: 'Create an account',
  title2: 'or log in',
  subtitle1: 'Enter the id of your 1win account ',
  subtitle2: 'registered with the promo code ',
  buttonCopy: 'Copy promo code',
  buttonRegister: 'Register',
  checkboxLabel: 'I confirm that I have read and fully agree with',
  checkboxUrl: 'Terms of the Bot Use Agreement',
}


const languageDataAuth = {
  BY: cisRevsh,
  RU: cisRevsh,
  KZ: cisRevsh,
  KG: cisRevsh,
  UA: cisRevsh,
  TM: cisRevsh,
  fr: frRevsh,
  ru: cisRevsh,
  ES: esRevsh,
  AZ: azCPA,
  IN: inCPA,
};

export { languageDataAuth, enDefaultRevsh };
