export const promos = {
	BD92: {
		revshare: 'BD92',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	ND28: {
		revshare: 'ND28',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	undefined: {
		revshare: 'BOTAI',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	GPTS3: {
		revshare: 'GPTS3',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	BD8G: {
		revshare: 'BD8G',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	BF92: {
		revshare: '8HF0',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	'287G': {
		revshare: '287G',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},

	/////
	
	'28FG': {
		revshare: '28FG',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	'782G': {
		revshare: '782G',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	JB42: {
		revshare: 'JB42',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	K29H: {
		revshare: 'K29H',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	FHB2: {
		revshare: 'FHB2',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	///
	JFBA: {
		revshare: 'JFBA',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	'8GF1': {
		revshare: '8GF1',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	KAJG: {
		revshare: 'KAJG',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	JBKK: {
		revshare: 'JBKK',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	NF20: {
		revshare: 'NF20',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	FB92: {
		revshare: 'FB92',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	BF98: {
		revshare: 'BF98',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	NOW8: {
		revshare: 'NOW8',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	CRASHGPT: {
		revshare: 'CRASHGPT',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	GPT2: {
		revshare: 'GPT2',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	KJNW: {
		revshare: 'KJNW',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
	leisure: {
		revshare: 'BOTAI',
		cpaAZ: 'GPTS',
		cpaIN: 'LH156',
	},
}

// 1. BD92
// 2. ND28
// 3. BD8G
// 4. 8HF0
// 5. 287G

// 6. BF92
// 7. 28FG
// 8. 782G
// 9. BF92
// 10. 28FG
// 11. 782G

// 12. 8GF1
// 13. KAJG
// 14. JFBA
// 15. JBKK
// 16. NF20
// 17. FB92
// 18. BF98
// 19. NOW8
// 20. KJNW

// 1. JFBA
// 2. KAJG
// 3. 8GF1
// 4. 782G
// 5. 28FG
// 6. BF92
// 7. 287G
// 8. 8HF0
// 9. BD8G
// 10. ND28
// 11. BD92
// 12. JBKK
// 13. NF20
// 14. FB92
// 15. BF98
// 16. NOW8
// 17. KJNW

// 18. FHB2
// 19. K29H
// 20. JB42
