const languageDataAlerts = {
  ru: {
    errorSign:
      'Ошибка: ID аккаунта не обнаружен в системе. Проверьте правильность ввода или создайте новый аккаунт.',
    errorDeposit:
      'Ошибка: Неверный депозит. Для доступа к сигналам сделайте соответствующий депозит.',
  },
  en: {
    errorSign:
      'Error: Account ID is not detected in the system. Check if you entered it correctly or create a new account.',
    errorDeposit:
      'Error: Invalid deposit. To access the signals, make an appropriate deposit.',
  },
  fr: {
    errorSign:
      'Erreur : Lidentifiant du compte nest pas detecte dans le systeme. Verifiez si vous lavez saisi correctement ou creez un nouveau compte.',
    errorDeposit:
      'Erreur : Depot non valide. Pour acceder aux signaux, effectuez un depot approprie.',
  },
  es: {
    errorSign:
      'Error: No se ha detectado el ID de cuenta en el sistema. Compruebe si lo ha introducido correctamente o cree una cuenta nueva.',
    errorDeposit:
      'Error: Deposito no valido. Para acceder a las senales, realice un deposito adecuado.',
  },
};



export default languageDataAlerts;
