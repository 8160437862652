import React from 'react';

import './index.scss';

function Preloader() {
  return (
    <div className='middle'>
      <div className='animation'>
        <div className='content'>
          <div className='item bg-shine' />
          <div className='item bg-circle' />
          <div className='item mask'>
            <div className='item bg bg-man' />
          </div>
          <div className='item bg bg-man' style={{ height: '150px' }} />
          <div
            className='item bg bg-man'
            style={{
              width: '120px',
              backgroundPositionX: '-201px',
              marginLeft: '200px',
              height: '286px',
            }}
          />
          <div className='item name'>
            <div className='item bg bg-l' />
            <div className='item bg bg-u' />
            <div className='item bg bg-c' />
            <div className='item bg bg-k' />
            <div className='item bg bg-y' />
            <div className='item bg bg-j' />
            <div className='item bg bg-e' />
            <div className='item bg bg-t' />
          </div>
          <div className='item bg bg-star' />
          <div className='item bg bg-star bg-star-2' />
        </div>
      </div>
    </div>
  );
}

export default Preloader;
