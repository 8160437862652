import axios from 'axios'

const API_BASE_URL = 'https://luckyjet.casinohack.site/'

const api = axios.create({
	withCredentials: true,
	baseURL: API_BASE_URL,
})

api.interceptors.request.use(config => {
	config.headers.referral = `Ref ${localStorage.getItem('referral')}`
	return config
})

export default api
